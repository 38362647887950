import type { LoaderFunctionArgs, MetaFunction } from "@remix-run/node";
import { Link, redirect } from "@remix-run/react";
import { $path } from "remix-routes";
import { Button } from "~/components/ui/button";
import { isAuthenticated } from "~/services/auth.server";

export const meta: MetaFunction = () => {
	return [{ title: "immedio Box" }];
};

export async function loader({ request }: LoaderFunctionArgs) {
	const authenticated = await isAuthenticated(request);
	if (authenticated) {
		return redirect($path("/tenants"));
	}
	return null;
}

export default function Index() {
	return (
		<div className="flex flex-col items-center justify-center min-h-screen bg-gray-50">
			<div className="w-full max-w-md p-6 bg-white rounded-lg shadow-md">
				<img
					src="immedio-box.svg"
					alt="immedio Box"
					className="w-full h-auto py-4"
				/>
				<div className="flex justify-between items-center">
					<a
						href="https://www.immedio.io/immedio-box-lp"
						target="_blank"
						rel="noreferrer"
					>
						<Button variant="link" className="text-sky-400 hover:text-sky-500">
							immedio Boxについて
						</Button>
					</a>
					<Link to={$path("/auth/login")}>
						<Button variant="link" className="text-sky-400 hover:text-sky-500">
							ログイン
						</Button>
					</Link>
				</div>
			</div>
		</div>
	);
}
